'use strict'

/*
 * LazyLoad медиафайлов
 * Антиспам
 * Получить GET параметр
 * Плавный скрол к маяку по клику
 * Ссылка на блок
 * Настройка меню
 * Собираем данные сквозной аналитики Битрикс
 * [CF7] Валидация
 * Маска для полей телефонов и ИНН
 * Функция анимации скрытия окна
 * Функция анимации появления окна
 * Слайдеры
 * [bootstrap] Включаем всплывашки
 * Цели Яндекс.Метрики
 * Добавление и удаление класса .active
 * Клики по кнопкам, для заполнения форм
 */

import swal from 'sweetalert'
import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);

/**
 * LazyLoad медиафайлов
 */
// Установите параметры глобально
// чтобы заставить LazyLoad самоинициализироваться
window.lazyLoadOptions = {
    // Ваши пользовательские настройки находятся здесь

};
// Прослушайте событие инициализации
// и получите экземпляр отложенной загрузки
window.addEventListener(
    "LazyLoad::Initialized",
    function (event) {
        window.lazyLoadInstance = event.detail.instance;
    },
    false
);

document.addEventListener("DOMContentLoaded", ready);
function ready() {
    /**
     * Антиспам
     */
    let antispam = document.querySelectorAll('input[name="agree"]');
    antispam.forEach(function (checkbox) {
        checkbox.checked = false;
        const btn = checkbox.closest('form').querySelector('.wpcf7-form-control.wpcf7-submit');
        btn.disabled = false;
    });

    let antispamSoglasie = document.querySelectorAll('.items_soglasie .item_soglasie div');
    antispamSoglasie.forEach(function (clickBtn) {
        clickBtn.onclick = function () {
            clickBtn.classList.toggle('active');
            clickBtn.closest('.items_soglasie').querySelector('input[type="checkbox"]').click();
        };
    });

    /**
     * Получить GET параметр
     */
    const gets = (function() {
        let url = window.location.search;
        let object = new Object();
        url = url.substring(1).split("&");
        for (let i = 0; i < url.length; i++) {
            object[url[i].split("=")[0]] = url[i].split("=")[1];
        }
        return object;
    })();

    /**
     * Плавный скрол к маяку (Beacon) по клику
     */
    const btnScrollBeacon = document.querySelectorAll('*[data-where]');
    btnScrollBeacon.forEach(el => {
        el.addEventListener("click", () =>{
            let where = el.getAttribute('data-where');
            const scrollTarget = document.getElementById(where).getBoundingClientRect();
            const elementPosition = scrollTarget.top - 95;
            window.scrollBy({
                top: elementPosition,
                behavior: 'smooth'
            });
        });
    });

    /**
     * Ссылка на блок
     */
    const btnLinkJS = document.querySelectorAll('.link-js');
    btnLinkJS.forEach(function (clickBtn) {
        clickBtn.onclick = function () {
            const link = clickBtn.getAttribute('data-link');
            if (link) {
                if (link.indexOf('http') >= 0) {
                    window.location.href = link;
                } else {
                    window.location.href = 'https://' + document.location.host + link;
                }
            }
        };
    });

    /**
     * Настройка меню
     */
    const headerMenuSite = document.querySelector('#header > .menu');
    if (headerMenuSite) {
        console.log(1);
        if (window.outerWidth >= 991) {
            window.onscroll = () => {
                const top = window.pageYOffset;
                const haveClassFixed = headerMenuSite.classList.contains('fixed');
                const headerLogo = headerMenuSite.querySelector('.logo');
                const haveClassLogo = headerLogo.classList.contains('visible');

                if (top > 74) {
                    if (!haveClassLogo) {
                        headerLogo.classList.add('visible');
                    }
                    if (!haveClassFixed) {
                        headerMenuSite.classList.add('fixed', 'py-2', 'bg-white', 'shadow-sm', 'mt-0');
                        headerMenuSite.classList.remove('mt-3');
                    }
                }
                if (top < 74) {
                    if (haveClassLogo) {
                        headerLogo.classList.remove('visible');
                    }
                    if (haveClassFixed) {
                        headerMenuSite.classList.remove('fixed', 'py-2', 'bg-white', 'shadow-sm', 'mt-0');
                        headerMenuSite.classList.add('mt-3');
                    }
                }
            }
        } else {
            //Мобильное меню
            const btnMobileMenu = document.getElementById('mobile-menu');
            btnMobileMenu.addEventListener("click", () => {
                if (!document.getElementById('menu-paste').innerText > 0) {
                    let copyMenu = headerMenuSite.querySelector('#menu-header-menu');
                    document.getElementById('menu-paste').appendChild(copyMenu);
                }
            });
        }
    }

    /**
     * Собираем данные сквозной аналитики Битрикс
     */
    const btnCF7Bitrix24 = document.querySelectorAll('.wpcf7-form-control.wpcf7-submit');
    btnCF7Bitrix24.forEach(clickBtn => {
        clickBtn.onmouseover = function () {
            let traceInput = this.closest('form').querySelector('input[name="TRACE"]');
            if (b24Tracker) {
                if (traceInput) {
                    traceInput.value = b24Tracker.guest.getTrace();
                }
            }
        };
    });

    /**
     * [CF7] Валидация
     */
    const btnWpcf7 = document.querySelectorAll('.wpcf7-submit');
    btnWpcf7.forEach(el => {
        el.addEventListener("click", () =>{
            const btnText = el.value;
            setTimeout(()=>{
                el.setAttribute('disabled', 'disabled');
            },1);
            let timer;
            let x = 5;
            function countdown(){
                x--;
                if (x<=0){
                    el.removeAttribute('disabled');
                    el.value = btnText;
                }
                else {
                    timer = setTimeout(countdown, 1000);
                    el.value = `${x} Проверяем данные..`;
                }
            }
            countdown();
        });
    });

    document.addEventListener( 'wpcf7submit', function( event ) {
        //console.log(event.detail);
        const form = document.getElementById(`${event.detail.unitTag}`);
        const btn = form.querySelector('.wpcf7-submit');
        const status = event.detail.status;
        if (status === "mail_sent") {
            const popUpClose = form.closest('.popup');
            if (popUpClose) {
                popUpClose.querySelector('span.close').click();
            }else {
                btn.value = 'Отправлено!';
                btn.setAttribute('disabled', 'disabled');
            }
            swal({
                title: "Отлично!",
                text: event.detail.apiResponse.message,
                icon: "success",
                timer: 5000,
                button: {
                    text: "Закрыть",
                },
            });
        }else {
            const message = event.detail.apiResponse.message;
            const errors = event.detail.apiResponse.invalid_fields;
            console.log(event.detail);
            swal({
                title: "Ошибка!",
                text: message,
                icon: "warning",
                timer: 5000,
                button: {
                    text: "Исправлю..",
                },
            });
            for (let key in errors) {
                const id = errors[key].idref;
                const el = document.getElementById(id);
                el.classList.remove('is-valid');
                el.classList.add('is-invalid');
            }
            setTimeout(() => {
                const isValidEl = form.querySelectorAll('[aria-invalid="false"]');
                if (isValidEl){
                    isValidEl.forEach(el => {
                        el.classList.remove('is-invalid');
                        el.classList.add('is-valid');
                    });
                }
            }, 500);
        }
    }, false);

    /**
     * Маска для полей телефонов и ИНН
     */
    function maskPhone(selector, masked = '8 (___) ___-__-__') {
        const elems = document.querySelectorAll(selector);

        function mask(event) {
            const keyCode = event.keyCode;
            const template = masked,
                def = template.replace(/\D/g, ""),
                val = this.value.replace(/\D/g, "");
            let i = 0,
                newValue = template.replace(/[_\d]/g, function (a) {
                    return i < val.length ? val.charAt(i++) || def.charAt(i) : a;
                });
            i = newValue.indexOf("_");
            if (i !== -1) {
                newValue = newValue.slice(0, i);
            }
            let reg = template.substr(0, this.value.length).replace(/_+/g,
                function (a) {
                    return "\\d{1," + a.length + "}";
                }).replace(/[+()]/g, "\\$&");
            reg = new RegExp("^" + reg + "$");
            if (!reg.test(this.value) || this.value.length < 5 || keyCode > 47 && keyCode < 58) {
                this.value = newValue;
            }
            if (event.type === "blur" && this.value.length < 5) {
                this.value = "";
            }

        }

        for (const elem of elems) {
            elem.addEventListener("input", mask);
            elem.addEventListener("focus", mask);
            elem.addEventListener("blur", mask);
        }

    }
    maskPhone('input[type="tel"]');

    /**
     * Функция анимации скрытия окна
     * @param el
     * @param displayClass
     */
    function fadeOut(el, displayClass = 'd-none') {
        if (el) {
            if (!el.classList.contains(displayClass)) {
                let op = 1;
                let timer = setInterval(function () {
                    if (op <= 0.1) {
                        clearInterval(timer);
                        el.classList.remove('d-block');
                        el.classList.add(displayClass);
                    }
                    el.style.opacity = op;
                    el.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op -= op * 0.1;
                }, 10);
            }
        }
    }

    /**
     * Функция анимации появления окна
     * @param el
     * @param displayClass
     */
    function fadeIn(el, displayClass = 'd-block') {
        if (el) {
            if (!el.classList.contains(displayClass)) {
                let op = 0.1;
                el.classList.remove('d-none');
                let timer = setInterval(function () {
                    if (op >= 1) {
                        clearInterval(timer);
                        el.classList.add(displayClass);
                    }
                    el.style.opacity = op;
                    el.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op += op * 0.1;
                }, 10);
            }
        }
    }

    /**
     * Слайдеры
     */


    /**
     * [bootstrap] Включаем всплывашки
     */
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    });

    /**
     * Цели Яндекс.Метрики
     */
    function yaMetrics(el, id_metric, event) {
        if (document.querySelector(el)){
            const numMetric = '3235447';
            document.querySelectorAll(el).forEach(function (elem) {
                if (event === 'onclick'){
                    elem.setAttribute(event, `ym(${numMetric},'reachGoal','${id_metric}');return true;`);
                    return true;
                }else {
                    document.addEventListener( 'wpcf7submit', function( event ) {
                        if (event.detail.status === "mail_sent") {
                            ym(numMetric,'reachGoal',id_metric);
                            return true;
                        }
                    });
                }

            });
        }
    }
    // проверка цели ?_ym_debug=1
    //Header
    yaMetrics('#top-menu .logo_end_contacts .connect-btn .btn', 'shapka_1', 'onclick');
    //Footer
    yaMetrics('#footer-widget-1 .btn', 'podval_1', 'onclick');
   
    /**
     * При клике по кнопке меняем текст или картинку
     */
    function imgAndTextReplace(
        parent,
        elementsContainer,
        children,
        containerImg,
        text = false
    ) {
        if (parent){
            parent = document.querySelector(parent);
            const elements = parent.querySelector(elementsContainer)
            if (elements){
                elements.addEventListener('click', ({target})=>{
                    const el = target;
                    if (el.classList.contains(children)){
                        // Работаем с заменой картинок
                        const srcImg = el.getAttribute('data-img-src');
                        const img = parent.querySelector(`${containerImg} img`);
                        img.src = srcImg;
                        // Работаем с заменой текста
                        if (text){
                            const textAttr = el.getAttribute('data-text');
                            const textValue = elements.getElementsByClassName('modifiable');
                            for (let i = 0; i < textValue.length; i++) {
                                if (textValue[i].attributes[0].nodeValue === textAttr) {
                                    textValue[i].classList.add('visible');
                                } else {
                                    textValue[i].classList.remove('visible');
                                }
                            }
                        }
                    }
                });
            }else {
                console.error('Контейнер с элементами не найден!');
            }
        }else {
            console.error('Параметр parent не найден!');
        }
    }
    imgAndTextReplace(
        '.page-home.team',
        '.info',
        'link',
        '.photos-person',
        true
    );
    imgAndTextReplace(
        '.page-home.mackets',
        '.wrapper',
        'link',
        '.promo',
        true
    );
    imgAndTextReplace(
        '.page-home.work',
        '.accordion',
        'accordion-button',
        '.photo'
    );

    /**
     * Добавление и удаление класса .active
     */
    function elementAddAndRemoveClassActive(parent, child) {
        if (parent){
            const linkDecorContainer = document.querySelector(parent);
            linkDecorContainer.addEventListener('click', ({target}) => {
                if (target.classList.contains(child.slice(1))){
                    const allChild = linkDecorContainer.querySelectorAll(child);
                    allChild.forEach((e) => {
                        e.classList.remove('active');
                    });
                    if (target.classList.contains('active')) {
                        target.classList.remove('active');
                    } else {
                        target.classList.add('active');
                    }
                }
            });
        }
    }
    elementAddAndRemoveClassActive('.page-home.mackets .category', '.link-decor');
    elementAddAndRemoveClassActive('.page-home.team .prof', '.link-decor');


    /**
     * Клики по кнопкам, для заполнения форм
     */
    // Главная страница

    // Страница: /tariffs/
    const priceContainer = document.querySelector('section.page-home .card-list');
    const modalTitle = document.querySelector('.modal-title');
    priceContainer.addEventListener('click', ({target})=>{
        const el = target;
        if (el.getAttribute('data-bs-target') === '#application'){
            // const modal = document.getElementById(el.getAttribute('data-bs-target'));
            const itemParent = el.closest('li');
            const tariffName = itemParent.querySelector('p.card-title').innerText,
                tariffPrice = itemParent.querySelector('p.card-price').innerText;
            modalTitle.innerHTML = tariffName + '<br>' + tariffPrice;
        }
    });
}